mcWeb.cartsmall = jQuery.extend(true, mcWeb.cartsmall, new function () {

    this.vm = function (id) {
        var $ = jQuery;
        var self = this;

        if ($('.body-checkout').length > 0) {
            self.load = function (data, settings) {
            };
            return; //skipp
        }

        self.id = "#" + id;
        self.service = new WebApiService("Cart");
        self.popoverNode = null;

        self.popoverOpen = ko.observable(false);

        self.priceTotal = ko.observable();
        self.priceTotalInkVat = ko.observable();

        self.priceTotalProducts = ko.observable();
        self.priceTotalProductsInkVat = ko.observable();
        self.priceTotalProductInkVatLessDiscounts = ko.observable('');
        self.priceTotalProductExVatLessDiscounts = ko.observable('');
        self.priceTotalInkVatExShipping = ko.observable();
        self.priceTotalExVatExShipping = ko.observable();
         
        self.summaryPreGiftcardsInkMva = ko.observable();
        self.summaryPreGiftcardsEksMva = ko.observable();
        self.giftcardsTotal = ko.observable();
        self.giftVoucherUsedValue = ko.observable('');
        self.giftVoucherUsedValueExVat = ko.observable('');
        self.priceTotalInkCarrier = ko.observable();
        self.priceTotalInkCarrierInkVat = ko.observable();
        self.vatTotal = ko.observable(0);
        self.vatTotalInkCarrier = ko.observable();

        self.hasCart = ko.observable();
        self.cartName = ko.observable();
        self.displayDate = ko.observable();
        self.itemCount = ko.observable();
        self.favoriteCount = ko.observable(0);
        self.cartItems = ko.observableArray();
        self.orderbooks = ko.observableArray();

        self.showPriceDisplayControls = false;
        self.chkPriceIncExVat = ko.observable();
        self.chkShowVeilPris = false;
        self.chkHideCustomerPice = false;

        self.isLoggedIn = ko.observable();

        self.showPriceIncExVat = false;
        self.showRemoveButton = false;
        self.showCart = ko.observable(true);
        self.showRemoveCart = ko.observable(false);
        self.OrderbookDate = ko.observable('');
        self.OrderbookActive = ko.observable(false);
        self.showGoToCart = false;
        self.showGoToCheckout = false;
        self.showExternalCheckout = false;

        //Removed in 2016 design (code is used by old design)
        self.addToCartNotifier = ko.observable(false);
        self.addToCartNotifierText = ko.observable('');
        self.addToCartNotifierIsActive = ko.observable(false);
        //End removed

        self.showLeasingCosts = ko.observable(false);
        self.doNotShowLeasingCosts = ko.pureComputed(function () {
            return !self.showLeasingCosts() || self.LeasingTermAmountExVat().length <= 0;
        });

        self.LeasingTermAmountExVat = ko.observable('');
        self.smallCartFees = false;

        self.isVisible = ko.observable(false);
        self.isCartItemsSet = ko.observable(false);
        self.favoriteCartUrl = '';
        self.offerCartUrl = '';

        self.isSoftLog = null;

        self.decimalsQuantity = 0;

        self.popupTime = 2500;
        self.showOrderbookSummary = ko.observable(false);
        self.smallCartItemTemplate = ko.computed(function () {
            if (self.showLeasingCosts())
                return 'cartsmallitem-leasing-template';
            return 'cartsmallitem-template'
        });

        
        self.smallCartContentId = ko.observable('small-cart-content');


        self.invoiceFee = ko.observable();
        self.invoiceFeeInkVat = ko.observable();

        self.invoiceFeeAndShipping = ko.observable();
        self.invoiceFeeAndShippingInkVat = ko.observable();
        self.SummarySumCostReductionIncVat = ko.observable();
        self.summarySumAfterCostReductionsIncVat = ko.observable();
        self.disableIncExVatChange = false;
        self.offerCount = ko.observable(0);

        self.cartHashCode = null;
        self.guId = null;

        $(document).ready(function () {
            self.popupMsg = $('#NewItemInCartNotifier');
            self.popupMsgWait = self.popupMsg.find('.pop-wait');
            self.popupMsgSuccess = self.popupMsg.find('.pop-success');
            self.popupMsgError = self.popupMsg.find('.pop-error');
            self.popupMsgText = self.popupMsg.find('.cart-popup-text');
        });


        self.numberOfItemsToShow = ko.observable(0);
        self.cartItemsToShow = ko.computed(function () {
            var items = [];

            var itemsToShow = self.cartItems().length;
            if (self.numberOfItemsToShow() > 0)
                itemsToShow = Math.min(self.cartItems().length, self.numberOfItemsToShow());

            for (var i = 0; i < itemsToShow; i++) {
                items.push(self.cartItems()[i]);
            }

            return items;
        });

        PubSub.subscribe(mcWeb.cart.events.onChangedCart, function (message, data) {
            self.load(data);
        });

        /*Quantity Small Cart changed*/
        PubSub.subscribe(mcWeb.cart.events.onChangedQuantityCart, function (message, data) {
            var product = null;

            for (var i = 0; i < self.cartItems().length; i++) {
                if (self.cartItems()[i].ProductId === data.ProductId) {
                    product = self.cartItems()[i];
                    break;
                }
            }

            if (product !== null) {
                self.resultQuantity(data.result, product, data.newQuantity);
            } else {
                self.load();
            }
        });

        PubSub.subscribe(mcWeb.cartsmall.events.onUpdateCartForceLoad, function (message, data) {
            self.load(data, null, true);
        });

        PubSub.subscribe(mcWeb.cartsmall.events.onUpdateCart, function (message, data) {
            self.load(data);
        });

        PubSub.subscribe(mcWeb.cartsmall.events.onIsCartChanged, function (message) {
            var param = {
                guId: self.guId,
                cartHashCode: self.cartHashCode
            };
            
            self.service.GetAction("IsCartChanged", param).done(function (result) {
                if (result === true) {
                    self.load(null, null, true);
                }
            });
        });


        PubSub.subscribe(mcWeb.cartsmall.events.onChangeVat, function (message, inkVat) {
            try {

                //Warning changeing VAT reload page!!!

                if (inkVat === null)
                    return;

                if (self.chkPriceIncExVat() === inkVat) //No change
                    return;

                self.chkPriceIncExVat(inkVat);
                self.setVat(inkVat);
            } catch (e) {
                console.log("CartSmallView - Ex: " + e);
            }
        });


        self.doShowActiveCart = function () {
            self.smallCartContentId('small-cart-content');
            self.showOrderbookSummary(false);
            window.StorageService.localStorage.setItem('orderbook.cartivew', 'active-cart');
        };

        self.doShowOrderbookSummary = function () {
            self.showOrderbookSummary(true);
            self.smallCartContentId('small-cart-orderbook-content');
            window.StorageService.localStorage.setItem('orderbook.cartivew', 'summary');
            mcWeb.orderbookManager.showOrderbookSummaryInline('.popover #small-cart-orderbook-summary');
        };

        self.load = function (data, settings, forceLoad) {

            self.showSmallCartAmount();

            //Settings
            if (settings) {
                self.showGoToCart = settings.ShowGoToCart;
                self.showGoToCheckout = settings.ShowGoToCheckout;
                self.showExternalCheckout = settings.ShowExternalCheckout
                self.showPriceIncExVat = settings.ShowPriceIncExVat;
                self.showRemoveButton = settings.ShowRemoveButton;
                self.showPriceDisplayControls = settings.ShowPriceDisplayControls;
                self.chkShowVeilPris = settings.ShowVeilPrisValue;
                self.chkHideCustomerPice = settings.HideCustomerPriceValue;
                self.favoriteCartUrl = settings.FavoriteCartUrl;
                self.offerCartUrl = settings.OfferCartUrl;
                self.isLoggedIn(settings.IsLoggedIn);
                self.numberOfItemsToShow(settings.SmallCartItemsToShow);
                self.decimalsQuantity = settings.DecimalsQuantity;
                self.popupTime = settings.PopupTime;
                self.disableIncExVatChange = settings.DisableIncExVatChange;
                self.smallCartFees = settings.SmallCartFees;
                self.showIncVatCheckbox = settings.ShowIncVatCheckbox;
            }

            //If empty, try to load data with ajax
            if (!data) {
                self.isVisible(false);

                var param = {
                    cartName: null,
                    useVat: self.chkPriceIncExVat(),
                    hasCarrier: false,
                    guid: getCartIdFromSessionStorage(),
                    favGuid: mcWeb.cartsmall.getFavoriteGuid(),
                    forceLoad: forceLoad
                };

                self.service.GetAction("LoadCart", param).done(function (result) {

                    if (result !== null) {
                        self.load(result);
                    }
                });
                return;
            }

            if (data.CartName !== null)
                self.cartName(data.CartName);
            if(data.DisplayDate != null)
                self.displayDate(data.DisplayDate);

            if (data.Orderbooks !== null) {
                self.orderbooks(data.Orderbooks);
                var cartView = window.StorageService.localStorage.getItem('orderbook.cartivew');
                if (cartView === 'summary') {
                    self.doShowOrderbookSummary();
                } else {
                    self.doShowActiveCart();
                }

            }
            //todo: fiks det slik at vi viser ordrebok sammendraget når vi ikke har noen ordrebøker men vi er i ordrebok shopppen og ikke kan vise vanlig handlevogn
            //vi har en setting vi må se på (OrderbookIncludesDefaultCart) og hvorvidt ordrebokshoppen er aktiv.
            if ((data.Orderbooks == null || data.Orderbooks.length === 0) && (data.OrderbookActive != null && data.OrderbookActive === true)) {
                self.doShowOrderbookSummary();
                window.StorageService.localStorage.setItem('orderbook.cartivew', 'summary');
            }
                
            self.OrderbookActive(data.OrderbookActive);
            self.OrderbookIncludesDefaultCart = data.OrderbookIncludesDefaultCart;
            self.hasCart(data.HasCart);
            self.itemCount(mc.format.asCulture(data.ItemCount, self.decimalsQuantity));

            self.favoriteCount(data.FavoriteCount);
            PubSub.publish(mcWeb.cartsmall.events.onChangeFav, self.favoriteCount());

            self.priceTotal(data.PriceTotal);
            self.priceTotalInkVat(data.PriceTotalInkVat);

            self.priceTotalProducts(data.PriceTotalProducts);
            self.priceTotalProductsInkVat(data.PriceTotalProductsInkVat);
            self.priceTotalProductInkVatLessDiscounts(data.PriceTotalProductInkVatLessDiscounts);
            self.priceTotalProductExVatLessDiscounts(data.PriceTotalProductExVatLessDiscounts);
            self.summaryPreGiftcardsInkMva(data.SummaryPreGiftcardsInkMva);
            self.summaryPreGiftcardsEksMva(data.SummaryPreGiftcardsEksMva);
            self.giftcardsTotal(data.GiftcardsTotal);
            self.giftVoucherUsedValue(data.GiftVoucherUsedValue);
            self.giftVoucherUsedValueExVat(data.GiftVoucherUsedValueExVat);
            self.priceTotalInkVatExShipping(data.PriceTotalInkVatExShipping);
            self.priceTotalExVatExShipping(data.PriceTotalExVatExShipping);

            self.priceTotalInkCarrier(data.PriceTotalInkCarrier);
            self.priceTotalInkCarrierInkVat(data.PriceTotalInkCarrierInkVat);

            self.vatTotal(data.VatTotal);
            self.vatTotalInkCarrier(data.VatTotalInkCarrier);

            self.invoiceFee(data.InvoiceFee);
            self.invoiceFeeInkVat(data.InvoiceFeeInkVat);

            self.invoiceFeeAndShipping(data.InvoiceFeeAndShipping);
            self.invoiceFeeAndShippingInkVat(data.InvoiceFeeAndShippingInkVat);

            self.offerCount(data.OfferCount);
            if(data.SummarySumCostReductionIncVat == null)
                self.SummarySumCostReductionIncVat('');
            else
                self.SummarySumCostReductionIncVat(data.SummarySumCostReductionIncVat);

            if(data.SummarySumAfterCostReductionIncVat == null)
                self.summarySumAfterCostReductionsIncVat('');
            else{
                if(data.SummarySumAfterCostReductionIncVat == '' &&
                   data.SummarySumCostReductionIncVat =='' ){
                    self.summarySumAfterCostReductionsIncVat('');
                }
                else{
                    self.summarySumAfterCostReductionsIncVat(data.SummarySumAfterCostReductionIncVat);
                    self.priceTotalProductsInkVat(data.SummarySumAfterCostReductionIncVat);
                }
            }

            //Removed in 2016 design (code is used by old design)
            self.addToCartNotifier(data.AddToCartNotifier);
            self.addToCartNotifierText((data.AddToCartNotifierText !== null) ? data.AddToCartNotifierText : '');
            //End removed

            self.chkPriceIncExVat(data.IncVat);
            self.isLoggedIn(data.IsLoggedIn);
            if (data.OrderbookDate == null)
                self.OrderbookDate('');
            else self.OrderbookDate(data.OrderbookDate);

            mcWeb.cartsmall.setFavoriteGuid(data.FavoriteGuid, self.isLoggedIn());

            self.cartHashCode = data.CartHashCode;
            self.guId = data.GuId;

            if (data.ShowLeasingCosts !== null)
                self.showLeasingCosts(data.ShowLeasingCosts);

            if (data.LeasingTermAmountExVat !== null)
                self.LeasingTermAmountExVat(data.LeasingTermAmountExVat);
            else self.LeasingTermAmountExVat('');

            if (self.hasCart()) {
                ko.utils.arrayForEach(data.CartItems, function (item) {
                    if (!ko.isObservable(item.Quantity))
                        item.Quantity = ko.observable(mc.format.asCulture(item.Quantity, self.decimalsQuantity));
                    if (!ko.isObservable(item.QuantityMinimum))
                        item.QuantityMinimum = ko.observable(item.QuantityMinimum);
                    if (!ko.isObservable(item.Price1))
                        item.Price1 = ko.observable(item.Price1);
                    if (!ko.isObservable(item.Price2))
                        item.Price2 = ko.observable(item.Price2);

                    if (!ko.isObservable(item.LeasingItemCost)) {
                        item.LeasingItemCost = ko.observable(item.LeasingItemCost);
                    }
                    if (!ko.isObservable(item.LeasingTotalCost)) {
                        item.LeasingTotalCost = ko.observable(item.LeasingTotalCost);
                    }

                    if(!ko.isObservable(item.CostReductionIncVat)){
                        if(item.CostReductionIncVat == null)
                            item.CostReductionIncVat = ko.observable('');
                        else
                            item.CostReductionIncVat = ko.observable(item.CostReductionIncVat);
                    }
                    
                    if(!ko.isObservable(item.AmountAfterReductionIncVat)){
                        if(item.AmountAfterReductionIncVat == null)
                            item.AmountAfterReductionIncVat = ko.observable('');
                        else
                            item.AmountAfterReductionIncVat = ko.observable(item.AmountAfterReductionIncVat);
                    }
                        
                });
            }
            self.isVisible(true);
            self.cartItems(data.CartItems);
            self.isCartItemsSet(true);

            //Removed in 2016 design (code is used by old design)
            if (self.addToCartNotifier()) {
                self.ShowNewItemInCartNotifier();
            }
            //End removed

        };
        
        var getCartIdFromSessionStorage = function () {

            try {
                var guid = window.StorageService.sessionStorage.getItem('sCartId');
                if (guid === null || guid.length < 30)
                    return null;

                window.StorageService.sessionStorage.removeItem('sCartId'); //reset
                return guid;
            } catch (e) {
                // IOS/etc. Private Mode
                return null;
            }
        };

        self.setVat = function (incVat) {
            var data = {
                cartName: null,
                useVat: incVat,
                vatChange: true,
                hasCarrier: false
            };

            self.service.GetAction("LoadCart", data).done(function (result) {
                if (self.isCheckoutPage() || self.isCartPage()) {
                    PubSub.publish(mcWeb.cart.events.onChangedCart, result);
                } else {
                    location.reload(true);
                }
            });
        };

        self.setVeil = function (showVeil) {
            var data = {
                showVeilPris: showVeil
            };

            self.service.GetAction("ShowVeilPris", data).done(function (result) {

                location.reload(true);
            });
        };

        self.setHideCustomerPrice = function (hideCustomerPrice) {
            var data = {
                hideCustomerPrice: hideCustomerPrice
            };

            self.service.GetAction("HideCustomerPrice", data).done(function (result) {

                location.reload(true);
            });
        };



        this.onClose = function () {
            if (self.popoverNode !== null) {
                self.popoverNode.popover('hide');
                self.popoverOpen(false);
            }
        };

        this.onClickRemove = function (product) {
            self.updateQuantity(product, 0);
        };

        this.updateQuantity = function (product, newQuantity) {
            var data = {
                plid: product.ProductId,
                quantity: newQuantity,
                cartName: null
            };

            self.service.GetAction("UpdateCartItem", data).done(function (result) {
                if (result === null) {
                    PubSub.publish(mcWeb.cartsmall.events.onCartItemRemoved);
                    return;
                }

                PubSub.publish(mcWeb.freeShippingTxt.events.onUpdateFreeShipping);

                var pubObject = new Object();
                pubObject.result = result;
                pubObject.newQuantity = newQuantity;
                pubObject.ProductId = product.ProductId;

                self.resultQuantity(result, product, newQuantity);

                PubSub.publish(mcWeb.cartsmall.events.onChangedQuantityCartSmall, pubObject);
            });

        };

        this.resultQuantity = function (result, product, newQuantity) {

            self.hasCart(result.HasCart);
            self.itemCount(mc.format.asCulture(result.ItemCount, self.decimalsQuantity));

            self.favoriteCount(result.FavoriteCount);
            PubSub.publish(mcWeb.cartsmall.events.onChangeFav, self.favoriteCount());

            if (result.PriceTotal) {
                self.priceTotal(result.PriceTotal);
                self.priceTotalInkVat(result.PriceTotalInkVat);
                self.priceTotalProducts(result.PriceTotalExVatExShipping);
                self.priceTotalProductsInkVat(result.PriceTotalInkVatExShipping);
                self.priceTotalInkCarrier(result.PriceTotalInkCarrier);
                self.priceTotalInkCarrierInkVat(result.PriceTotalInkCarrierInkVat);
                self.priceTotalInkVatExShipping(result.PriceTotalInkVatExShipping);
                self.priceTotalExVatExShipping(result.PriceTotalExVatExShipping);
                self.priceTotalProductInkVatLessDiscounts(result.PriceTotalProductInkVatLessDiscounts);
                self.priceTotalProductExVatLessDiscounts(result.PriceTotalProductExVatLessDiscounts);
                self.giftVoucherUsedValue(result.GiftVoucherUsedValue);
                self.giftVoucherUsedValueExVat(result.GiftVoucherUsedValueExVat);
            }
            if (result.VatTotal) {
                self.vatTotal(result.VatTotal);
                self.vatTotalInkCarrier(result.VatTotalInkCarrier);
            }
            if (result.ShowLeasingCosts !== null)
                self.showLeasingCosts(result.ShowLeasingCosts);

            if (result.LeasingTermAmountExVat !== null)
                self.LeasingTermAmountExVat(result.LeasingTermAmountExVat);
            else self.LeasingTermAmountExVat('');

            if (!self.hasCart()) {
                if (self.popoverNode !== null) {
                    self.popoverNode.popover('hide');
                    self.popoverOpen(false);
                }

                self.cartItems.removeAll();
                PubSub.publish(mcWeb.cart.events.hasCartItems, self.hasCart());

            } else if (result.CartItems === null || (result.CartItems.length < 1)) {
                self.cartItems.remove(product);
            } else {
                if (result.CartItems !== null) {
                    product.Quantity(result.CartItems[0].Quantity);
                    product.Price1(result.CartItems[0].Price1);
                    product.Price2(result.CartItems[0].Price2);
                }
            }

            if (document.activeElement)
                document.activeElement.blur();
            if(result.RequireFullRefresh){
                PubSub.publish( mcWeb.cart.events.onChangedCart);
                if(mcWeb.ajaxRenderEngine != undefined)
                    PubSub.publish(mcWeb.ajaxRenderEngine.events.onPriceRefreshRequested,null);
                else location.reload(true);
            }
            else if(jQuery("body.login-type-Mport").length > 0){
                if(mcWeb.ajaxRenderEngine != undefined)
                    PubSub.publish(mcWeb.ajaxRenderEngine.events.onPriceRefreshRequested,null);
            }
        }



        this.onClickVat = function () {
            self.setVat(self.chkPriceIncExVat());
            return true; //Need this on Checkbox
        };

        this.onClickVeil = function () {
            self.setVeil(self.chkShowVeilPris);
            return true; //Need this on Checkbox
        };

        this.onClickHideCustomerPrice = function () {
            self.setHideCustomerPrice(self.chkHideCustomerPice);
            return true; //Need this on Checkbox
        };

        this.onClickProductLink = function (product) {
            window.location.href = product.ProductLink;
        };



        this.onClickGoToCart = function () {
            try {
                self.updateIcon(true);

                self.service.GetAction("GoToCart", "").done(function (result) {
                    self.updateIcon(false);
                    if (result === null)
                        return;
                    window.location.href = result;  //"http://" + location.hostname;			
                });
            } catch (e) {
                self.updateIcon(false);
            }
        };

        this.onSelectCart = function (item) {

            self.onSelectCartByName(item.Name);
        };

        this.onSelectCartByName = function (name) {

            var data = { ActiveCartName: name };
            self.service.PostAction("SetActiveCartName", data).done(function (result) {
                PubSub.publish(mcWeb.cart.events.onChangedCart, null);
            });
        };

        this.onGoToCart = function () {//not yet tested!
            try {
                var data = { cartName: self.cartName() };
                self.updateIcon(true);
                self.service.GetAction("GoToCart", data).done(function (result) {
                    self.updateIcon(false);
                    if (result === null)
                        return;
                    if (window.location.pathname !== result)
                        window.location.href = result;  //"http://" + location.hostname;			
                });
            } catch (e) {
                self.updateIcon(false);
            }
        };
        this.onClickGoToCheckout = function () {
            try {
                var data = { cartName: self.cartName, source: 'standard' };
                self.updateIcon(true);
                var action = "GoToCheckOut";

                self.service.GetAction(action, data).done(function (result) {
                    self.updateIcon(false);
                    if (result === null)
                        return;
                    window.location.href = result;  //"http://" + location.hostname;			
                });
            } catch (e) {
                self.updateIcon(false);
            }
        };
        self.getContent = function () {
            return "<div id='" + self.conId + self.kn + "'>" + $('#' + self.conId).html() + "</div>";
        };

        ko.bindingHandlers.popover = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                var node = $(element);
                self.popoverNode = node;
                var option = ko.utils.unwrapObservable(valueAccessor());
                var conId = option.contentid, titId = option.titleid, kn = "-kn";
                self.conId = conId;
                self.kn = kn;
                var contentkn = "<div id='" + conId + kn + "'>" + $('#' + conId).html() + "</div>";
                var titlekn = "<div id='" + titId + kn + "'>" + $('#' + titId).html() + "</div>";

                this.togglePopover = function () {
                    node.popover('toggle');
                    if (mcWeb.MegaMenuResponsive !== undefined) {
                        mcWeb.MegaMenuResponsive.setOverlay(false);
                    }
                    var conEl = document.getElementById(conId + kn);
                    var titEl = document.getElementById(titId + kn);
                    if (conEl !== undefined && conEl !== null)
                        ko.cleanNode(conEl);

                    ko.cleanNode(titEl);
                    ko.applyBindings(viewModel, conEl);
                    ko.applyBindings(viewModel, titEl);
                };

                var pop = node.popover({
                    title: titlekn,
                    content: self.getContent,
                    html: option.html,
                    trigger: 'manual',
                    sanitize: false,
                    //container: 'body', // Using this duplicates nodes in DOM. Not ideal.
                    placement: function (context) {
                        var con = jQuery(context);
                        con.addClass(option.contentClass);

                        var module = $('body.mc-inline-editor');
                        if (module.length > 0)
                            return 'bottom';

                        con.on('mouseleave', function () {
                            node.popover('hide');
                            self.popoverOpen(false);
                            return true;
                        });
                        return 'bottom';
                    }
                });


                self.mouseleaveCartSmall = function (event) {

                    if (!$(event.target).closest('.cart-small-popover').length) {
                        if (pop.is(":visible")) {
                            node.popover('hide');
                            self.popoverOpen(false);
                            event.stopPropagation();
                            return false;
                        }
                    }
                    return true;
                }

                pop.on('shown.bs.popover', function () {
                    $(document).bind('click touchstart', self.mouseleaveCartSmall);
                });
                pop.on('hide.bs.popover', function () {
                    $(document).unbind('click touchstart', self.mouseleaveCartSmall);
                });


                node.click(function () {

                    if (mcWeb.responsive.getScreenSize() === 'xs') {
                        //self.onClickGoToCart();
                        self.onClickGoToCheckout();
                        return;
                    }
                    if (self.showOrderbookSummary()) {
                        self.doShowOrderbookSummary();
                    }
                    togglePopover();
                    self.popoverOpen(!self.popoverOpen());

                    if (mcWeb.inlineEditor)
                        mcWeb.inlineEditor.init(true);
                });
            }
        };

        this.updateIcon = function (show) {
            var update = $('#UpdateIcon');
            if (update.length < 1)
                return;
            if (show)
                update.show();
            else
                update.hide();
        };

        this.showSmallCartAmount = function () {
            if (!self.isCheckoutPage() && mcWeb.responsive.isMedium()) {
                $(".cart-small-product-txt").show();
            }
        };

        this.isCheckoutPage = function () {
            return ($(".body-checkout").length > 0) ? true : false;
        };

        this.isCartPage = function () {
            return ($(".body-cart").length > 0) ? true : false;
        };


        this.ShowNewItemInCartNotifier = function () {
            var panel = $('.cart-popup-panel');
            if (panel.length > 0 && !self.addToCartNotifierIsActive()) {
                $('body').append(panel);
                self.addToCartNotifierIsActive(true);
                panel.fadeIn(500);
                panel.delay(1500);
                panel.fadeOut(1000);

                self.service.GetAction("HasShownAddToCartNotifier").done(function () {
                    self.addToCartNotifierIsActive(false);
                });
            }
        };

        this.switchToLeasing = function () {
            self.service.GetAction("SwitchToLeasing").done(function (result) {
                if(result)
                    PubSub.publish(mcWeb.cartsmall.events.onUpdateCart, null);
            });
        };

        this.switchToPayNow = function () {
            self.service.GetAction("SwitchToPayNow").done(function (result) {
                if(result)
                    PubSub.publish(mcWeb.cartsmall.events.onUpdateCart, null);
            });
        };


        PubSub.subscribe(mcWeb.login.events.onIsloggedIn, function (message, isLoggedIn) {
            self.isLoggedIn(isLoggedIn);
        });

        /// Data = {status: true/false, msg: 'Ny vare...'}
        PubSub.subscribe(mcWeb.cartsmall.events.onShowNewItemInCartText, function (message, data) {

            self.popupMsgWait.hide();
            self.popupMsgText.show();
            if (data.status === true) {
                self.popupMsgSuccess.show();
            } else {
                self.popupMsg.addClass("notifier-error");
                self.popupMsgError.show();
            }

            self.popupMsgText.html(data.msg);
            self.popupMsg.fadeIn();
            if (data.popupTime !== undefined && data.popupTime !== null) {
                self.popupTime = data.popupTime;
            }
            setTimeout(function () {
                PubSub.publish(mcWeb.cartsmall.events.onHideNewItemInCart);
            }, self.popupTime);
        });

        var isRunningPop = false;
        PubSub.subscribe(mcWeb.cartsmall.events.onShowNewItemInCart, function (message) {
            if (isRunningPop === true) {
                return;
            }

            isRunningPop = true;
            self.popupMsgText.hide();
            self.popupMsgWait.show();
            self.popupMsg.fadeIn();
            //IMPORTAN - Use "mcWeb.cartsmall.events.onShowNewItemInCartText" after this!
        });

        PubSub.subscribe(mcWeb.cartsmall.events.onHideNewItemInCart, function () {
            var popupTime = mc.format.asNumber(self.popupTime, 1, 1000);
            var dif = mc.format.asNumber(0.3, 2, 0.3);
            var value = popupTime * dif;
            var time = mc.format.asNumber(value, 1, 1000);

            self.popupMsg.fadeOut(time, function () {
                self.popupMsgSuccess.hide();
                self.popupMsgError.hide();
                self.popupMsg.removeClass("notifier-error");
            });

            isRunningPop = false;
        });

        PubSub.subscribe(mcWeb.cartsmall.events.onGotoSelectedCart,
            function (message, name) {
                self.onSelectCartByName(name);
                self.onGoToCart();
            });
    };

    this.setFavoriteGuid = function (favoriteGuid, isLoggedIn) {

        try {
            if (favoriteGuid === null || favoriteGuid.length < 30) {
                window.StorageService.localStorage.removeItem('sFavCartId');
            } else {
                var sFavCartId = (isLoggedIn) ? (favoriteGuid + ";l") : favoriteGuid;
                window.StorageService.localStorage.setItem('sFavCartId', sFavCartId);
            }
        } catch (e) {
            // IOS/etc. Private Mode
            console.log('CartSmallView.setFavoriteGuid - ex: ' + e);
        }
    };

    this.getFavoriteGuid = function () {

        try {
            var sFavCartId = window.StorageService.localStorage.getItem('sFavCartId');
            if (sFavCartId === null || sFavCartId.length < 30)
                return null;

            window.StorageService.localStorage.removeItem('sFavCartId'); //reset
            return sFavCartId;
        } catch (e) {
            // IOS/etc. Private Mode
            console.log('CartSmallView.getFavoriteGuid - ex: ' + e);
        }
        return null;
    };
});